import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FeaturedIn() {
    return (
        <Row>
            <h3 className={"mb-3 text-xl"}>საერთაშორისო მედია იუფიზე</h3>
            <hr />
            <div className="bg-white py-4">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <div className="grid grid-cols-2 gap-4 rounded-lg md:grid-cols-3 lg:gap-6">
                        <Link
                            to="https://www.forbes.com/search/?q=eufy"
                            target="_blank"
                        >
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/forbes.svg"
                                    alt="Transistor"
                                    width="158"
                                    height="48"
                                />
                            </div>
                        </Link>

                        <Link
                            to="https://www.businessinsider.com/answers#eufy%20security"
                            target="_blank"
                        >
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/business-insider.svg"
                                    alt="Tuple"
                                    width="148"
                                    height="48"
                                />
                            </div>
                        </Link>

                        <Link
                            to="https://www.nytimes.com/wirecutter/search/?s=eufy"
                            target="_blank"
                        >
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-3 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/the-new-york-times.svg"
                                    alt="Reform"
                                    width="250"
                                    height="48"
                                />
                            </div>
                        </Link>

                        <Link to="https://gizmodo.com/?s=eufy" target="_blank">
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/gizmodo.svg"
                                    alt="Tuple"
                                    width="158"
                                    height="48"
                                />
                            </div>
                        </Link>

                        <Link
                            to="https://www.theverge.com/search?q=eufy"
                            target="_blank"
                        >
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/the-verge.svg"
                                    alt="SavvyCal"
                                    width="158"
                                    height="48"
                                />
                            </div>
                        </Link>

                        <Link
                            to="https://www.cnet.com/search/?searchQuery=eufy"
                            target="_blank"
                        >
                            <div className="flex h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                                <img
                                    src="/images/featured/cnet.svg"
                                    alt="Statamic"
                                    width="120"
                                    height="48"
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Row>
    );
}

import { Link } from "react-router-dom";

export const SECURITY_CAMERAS = [
    {
        id: "eufy-IndoorCam-E220",
        imageUrl: "/images/products/eufy-indoorcam-e220.webp",
        title: "eufy IndoorCam E220",
        price: "169 ₾",
        oldPrice: "219 ₾",
        description: [
            {
                key: "რეზოლუცია:",
                value: "2K",
            },
            {
                key: "ხედვის არეალი:",
                value: "125°-360°",
            },
            {
                key: "კავშირი:",
                value: "Wi-Fi",
            },
            {
                key: "კვების წყარო:",
                value: "USB კაბელი",
            },
        ],
        shortDescription: [
            {
                key: "ჩაწერა:",
                value: "კამერა ჩაიწერს უწყვეტად ან მხოლოდ მოძრაობის დაფიქსირებისას.",
            },
            {
                key: "მონიტორინგი:",
                value: "ნებისმიერ დროს, ნებისმიერი ადგილიდან ლაივში აპლიკაციით ნახე ყველა მნიშვნელოვანი მომენტი 2K ხარისხში.",
            },
            {
                key: "მოძრაობის თრექინგი:",
                value: "კამერას შეუძლია მოძრაობის თრექინგი 360°-ით.",
            },
            {
                key: "აუდიო კომუნიკაცია:",
                value: "დაელაპარაკე კამერასთან მყოფებს აპლიკაციით.",
            },
            {
                key: "ჭკვიანი სახლის ინტეგრაციები:",
                value: "Apple HomeKit, Amazon Alexa, Google Assistant",
            },
            {
                key: (
                    <Link
                        className="underline"
                        to="https://www.eufy.com/privacy-commitment"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        კონფიდენციალური და დაშიფრული:
                    </Link>
                ),
                value: "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
            },
        ],
        video: "https://www.youtube.com/embed/S0j_ct7zUgk?si=8K36vdO3matiSwNx&amp;start=34",
        specs: [
            {
                key: "რეზოლუცია",
                value: "2K (2560 x 1440)",
            },
            {
                key: "სტატიკური ფართო ხედვის არეალი",
                value: "125°",
            },
            {
                key: "დინამიური ხედვის არეალი",
                value: (
                    <>
                        <p>360° ჰორიზონტალურად</p>
                        <p>96° ვერტიკალურად</p>
                    </>
                ),
            },
            {
                key: "ღამის ხედვა",
                value: "კი",
            },
            {
                key: "მოძრაობის დეტექტორი",
                value: "კი (ნებისმიერი მოძრაობის; ადამიანის; ბავშვის ტირილის; შინაური ცხოველის)",
            },
            {
                key: "მოძრაობის დაფიქსირებისას შეტყობინება",
                value: "კი",
            },
            {
                key: "სიგნალიზაცია",
                value: "კი",
            },
            {
                key: "მოძრაობის თრექინგი",
                value: "კი (360°)",
            },
            {
                key: "ორმხრივი აუდიო კომუნიკაცია",
                value: "კი",
            },
            {
                key: "მეხსიერება",
                value: "SD Card (არ მოყვება), Cloud, NAS (RTSP)",
            },
            {
                key: "ჩაწერის მეთოდი",
                value: "24/7 და მოძრაობის დაფიქსირებისას",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "კი",
            },
            {
                key: "ჭკვიანი ინტეგრაციები",
                value: "Google Assistant, Amazon Alexa, Apple Homekit",
            },
            {
                key: "წყალგამძლე",
                value: "არა",
            },
            {
                key: "კავშირი",
                value: "Wi-Fi (2.4GHz)",
            },
            {
                key: "კვების წყარო",
                value: "5V/2A USB კაბელი და ადაპტერი",
            },
            {
                key: "რა არის ყუთში",
                value: (
                    <>
                        <li>კამერა</li>
                        <li>USB ადაპტერი</li>
                        <li>Micro-USB კაბელი (2 მეტრი)</li>
                        <li>სამაგრი</li>
                        <li>ჭანჭიკები</li>
                        <li>ინსტრუქცია</li>
                    </>
                ),
            },
            {
                key: "გარანტია",
                value: "1 წელი",
            },
        ],
    },
    {
        id: "eufy-SoloCam-S220",
        imageUrl: "/images/products/eufy-solocam-s220.webp",
        title: "eufy SoloCam S220",
        price: "319 ₾",
        oldPrice: "399 ₾",
        description: [
            {
                key: "რეზოლუცია:",
                value: "2K",
            },
            {
                key: "ხედვის არეალი:",
                value: "135°",
            },
            {
                key: "კავშირი:",
                value: "Wi-Fi",
            },
            {
                key: "კვების წყარო:",
                value: "ბატარეა და მზის პანელი",
            },
        ],
        shortDescription: [
            {
                key: "ჩაშენებული მზის პანელი:",
                value: "მზის პანელი უზრუნველყოფს კამერის ბატარეის ყოველდღიურ დამუხტვას.",
            },
            {
                key: "მონიტორინგი:",
                value: "ნებისმიერ დროს, ნებისმიერი ადგილიდან ლაივში აპლიკაციით ნახე ყველა მნიშვნელოვანი მომენტი 2K ხარისხში.",
            },
            {
                key: "აუდიო კომუნიკაცია:",
                value: "დაელაპარაკე კამერასთან მყოფებს აპლიკაციით.",
            },
            {
                key: "ჭკვიანი სახლის ინტეგრაციები:",
                value: "Amazon Alexa, Google Assistant",
            },
            {
                key: (
                    <Link
                        className="underline"
                        to="https://www.eufy.com/privacy-commitment"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        კონფიდენციალური და დაშიფრული:
                    </Link>
                ),
                value: "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
            },
        ],
        video: "https://www.youtube.com/embed/KiSxCBObplU?si=lF07WBPyjlIFULoX",
        specs: [
            {
                key: "რეზოლუცია",
                value: "2K (2560 x 1440)",
            },
            {
                key: "ფართო ხედვის არეალი",
                value: "135°",
            },
            {
                key: "მზის პანელი",
                value: "კი",
            },
            {
                key: "ღამის ხედვა",
                value: "კი",
            },
            {
                key: "მოძრაობის დეტექტორი",
                value: "კი (ნებისმიერი მოძრაობის; ადამიანის; შინაური ცხოველის)",
            },
            {
                key: "მოძრაობის დაფიქსირებისას შეტყობინება",
                value: "კი",
            },
            {
                key: "სიგნალიზაცია",
                value: "კი",
            },
            {
                key: "ორმხრივი აუდიო კომუნიკაცია",
                value: "კი",
            },
            {
                key: "მეხსიერება",
                value: "8 GB EMMC, HomeBase 3",
            },
            {
                key: "ჩაწერის მეთოდი",
                value: "მოძრაობის დაფიქსირებისას",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "კი",
            },
            {
                key: "ჭკვიანი ინტეგრაციები",
                value: "Google Assistant, Amazon Alexa",
            },
            {
                key: "წყალგამძლე",
                value: "კი (IP67)",
            },
            {
                key: "კავშირი",
                value: "Wi-Fi (2.4GHz)",
            },
            {
                key: "კვების წყარო",
                value: "ბატარეა და მზის პანელი",
            },
            {
                key: "ბატარეა",
                value: "6500 mAh",
            },
            {
                key: "რა არის ყუთში",
                value: (
                    <>
                        <li>კამერა</li>
                        <li>USB-C კაბელი</li>
                        <li>სამაგრი</li>
                        <li>ჭანჭიკები</li>
                        <li>ინსტრუქცია</li>
                    </>
                ),
            },
            {
                key: "გარანტია",
                value: "1 წელი",
            },
        ],
    },
    {
        id: "eufy-SoloCam-S340",
        imageUrl: "/images/products/eufy-solocam-s340.webp",
        title: "eufy SoloCam S340",
        price: "569 ₾",
        oldPrice: "699 ₾",
        description: [
            {
                key: "რეზოლუცია:",
                value: "3K",
            },
            {
                key: "ხედვის არეალი:",
                value: "135°-360°",
            },
            {
                key: "კავშირი:",
                value: "Wi-Fi",
            },
            {
                key: "კვების წყარო:",
                value: "ბატარეა და მზის პანელი",
            },
        ],
        shortDescription: [
            {
                key: "მზის პანელი:",
                value: "მზის პანელი უზრუნველყოფს კამერის ბატარეის ყოველდღიურ დამუხტვას.",
            },
            {
                key: "მონიტორინგი:",
                value: "ნებისმიერ დროს, ნებისმიერი ადგილიდან ლაივში აპლიკაციით ნახე ყველა მნიშვნელოვანი მომენტი 3K ხარისხში.",
            },
            {
                key: "განათება და ფერადი ღამის ხედვა:",
                value: "მკაფიო გამოსახულება დღისა და ღამის განმავლობაში.",
            },
            {
                key: "აუდიო კომუნიკაცია:",
                value: "დაელაპარაკე კამერასთან მყოფებს აპლიკაციით.",
            },
            {
                key: "ჭკვიანი სახლის ინტეგრაციები:",
                value: "Amazon Alexa, Google Assistant",
            },
            {
                key: (
                    <Link
                        className="underline"
                        to="https://www.eufy.com/privacy-commitment"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        კონფიდენციალური და დაშიფრული:
                    </Link>
                ),
                value: "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
            },
        ],
        video: "https://www.youtube.com/embed/_gR3p1IyrzM?si=CgD7Afxgnh8SQzgQ",
        specs: [
            {
                key: "რეზოლუცია",
                value: (
                    <>
                        <p>ფართო კუთხოვანი: 3K (2880x1620)</p>
                        <p>ტელეფოტო: 2K (2304x1296)</p>
                    </>
                ),
            },
            {
                key: "სტატიკური ფართო ხედვის არეალი",
                value: "135°",
            },
            {
                key: "დინამიური ხედვის არეალი",
                value: (
                    <>
                        <p>360° ჰორიზონტალურად</p>
                        <p>70° ვერტიკალურად</p>
                    </>
                ),
            },
            {
                key: "გადიდება/Zoom",
                value: "8x",
            },
            {
                key: "განათება",
                value: "კი (100 Lumen)",
            },
            {
                key: "ფერადი ღამის ხედვა",
                value: "კი",
            },
            {
                key: "მოძრაობის დეტექტორი",
                value: "კი (ნებისმიერი მოძრაობის; ადამიანის; ავტომობილის)",
            },
            {
                key: "მოძრაობის დაფიქსირებისას შეტყობინება",
                value: "კი",
            },
            {
                key: "მოძრაობის თრექინგი",
                value: "კი (360°)",
            },
            {
                key: "სიგნალიზაცია",
                value: "კი",
            },
            {
                key: "ორმხრივი აუდიო კომუნიკაცია",
                value: "კი",
            },
            {
                key: "მეხსიერება",
                value: "8 GB EMMC, HomeBase 3",
            },
            {
                key: "ჩაწერის მეთოდი",
                value: "მოძრაობის დაფიქსირებისას",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "კი",
            },
            {
                key: "ჭკვიანი ინტეგრაციები",
                value: "Google Assistant, Amazon Alexa",
            },
            {
                key: "წყალგამძლე",
                value: "კი (IP67)",
            },
            {
                key: "კავშირი",
                value: "Wi-Fi (2.4GHz)",
            },
            {
                key: "კვების წყარო",
                value: "ბატარეა და მზის პანელი",
            },
            {
                key: "მზის პანელი",
                value: "კი (2.2W)",
            },
            {
                key: "ბატარეა",
                value: "10000 mAh",
            },
            {
                key: "რა არის ყუთში",
                value: (
                    <>
                        <li>კამერა</li>
                        <li>მზის პანელი</li>
                        <li>2x USB-C კაბელი</li>
                        <li>2x სამაგრი</li>
                        <li>2x ჭანჭიკები</li>
                        <li>ინსტრუქცია</li>
                    </>
                ),
            },
            {
                key: "გარანტია",
                value: "1 წელი",
            },
        ],
    },
    {
        id: "eufy-4G-LTE-Cam-S330",
        imageUrl: "/images/products/eufy-4g-lte-cam-S330.webp",
        title: "eufy 4G LTE Cam S330",
        price: "899 ₾",
        oldPrice: "1099 ₾",
        description: [
            {
                key: "რეზოლუცია:",
                value: "4K",
            },
            {
                key: "ხედვის არეალი:",
                value: "138°-360°",
            },
            {
                key: "კავშირი:",
                value: "4G LTE და Wi-Fi",
            },
            {
                key: "კვების წყარო:",
                value: "ბატარეა და მზის პანელი",
            },
        ],
        shortDescription: [
            {
                key: "მზის პანელი:",
                value: "მზის პანელი უზრუნველყოფს კამერის ბატარეის ყოველდღიურ დამუხტვას.",
            },
            {
                key: "უწყვეტი კავშირი:",
                value: "კამერა მუშაობს 4G/LTE სიმ ბარათსა და Wi-Fi-ზე. რომელიმეს შეფერხების შემთხვევაში გადაერთვება ხელმისაწვდომ კავშირზე.",
            },
            {
                key: "მონიტორინგი:",
                value: "ნებისმიერ დროს, ნებისმიერი ადგილიდან ლაივში აპლიკაციით ნახე ყველა მნიშვნელოვანი მომენტი 4K ხარისხში.",
            },
            {
                key: "განათება და ფერადი ღამის ხედვა:",
                value: "მკაფიო გამოსახულება დღისა და ღამის განმავლობაში.",
            },
            {
                key: "აუდიო კომუნიკაცია:",
                value: "დაელაპარაკე კამერასთან მყოფებს აპლიკაციით.",
            },
            {
                key: "ჭკვიანი სახლის ინტეგრაციები:",
                value: "Amazon Alexa, Google Assistant",
            },
            {
                key: (
                    <Link
                        className="underline"
                        to="https://www.eufy.com/privacy-commitment"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        კონფიდენციალური და დაშიფრული:
                    </Link>
                ),
                value: "ჩანაწერები ინახება მხოლოდ შენს კამერაში და დაშიფრულია AES-128 ალგორითმით, ლაივ კავშირი კი დაცული და უსაფრთხოა.",
            },
        ],
        video: "https://www.youtube.com/embed/28oANXDJUXA?si=qJmsrJNakBGyIgTb",
        specs: [
            {
                key: "რეზოლუცია",
                value: "4K (3840×2160)",
            },
            {
                key: "სტატიკური ფართო ხედვის არეალი",
                value: "138°",
            },
            {
                key: "დინამიური ხედვის არეალი",
                value: (
                    <>
                        <p>360° ჰორიზონტალურად</p>
                        <p>70° ვერტიკალურად</p>
                    </>
                ),
            },
            {
                key: "გადიდება/Zoom",
                value: "8x",
            },
            {
                key: "განათება",
                value: "კი (100 Lumen)",
            },
            {
                key: "ფერადი ღამის ხედვა",
                value: "კი",
            },
            {
                key: "მოძრაობის დეტექტორი",
                value: "კი (ნებისმიერი მოძრაობის; ადამიანის; ავტომობილის)",
            },
            {
                key: "მოძრაობის დაფიქსირებისას შეტყობინება",
                value: "კი",
            },
            {
                key: "მოძრაობის თრექინგი",
                value: "კი (360°)",
            },
            {
                key: "სიგნალიზაცია",
                value: "კი",
            },
            {
                key: "ორმხრივი აუდიო კომუნიკაცია",
                value: "კი",
            },
            {
                key: "მეხსიერება",
                value: "SD Card (მოყვება 32 GB), HomeBase 3",
            },
            {
                key: "ჩაწერის მეთოდი",
                value: "მოძრაობის დაფიქსირებისას",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "კი",
            },
            {
                key: "ჭკვიანი ინტეგრაციები",
                value: "Google Assistant, Amazon Alexa",
            },
            {
                key: "წყალგამძლე",
                value: "კი (IP55)",
            },
            {
                key: "კავშირი",
                value: "4G LTE და Wi-Fi (2.4GHz)",
            },
            {
                key: "კვების წყარო",
                value: "ბატარეა და მზის პანელი",
            },
            {
                key: "მზის პანელი",
                value: "კი (4.5W)",
            },
            {
                key: "ბატარეა",
                value: "9400 mAh",
            },
            {
                key: "რა არის ყუთში",
                value: (
                    <>
                        <li>კამერა</li>
                        <li>მზის პანელი</li>
                        <li>SD Card (32 GB)</li>
                        <li>2x USB-C კაბელი</li>
                        <li>2x სამაგრი</li>
                        <li>2x ჭანჭიკები</li>
                        <li>ინსტრუქცია</li>
                    </>
                ),
            },
            {
                key: "გარანტია",
                value: "1 წელი",
            },
        ],
    },
];

export const OTHER = [
    {
        id: "eufy-Indoor-Floor-Lamp-E10",
        imageUrl: "/images/products/eufy-indoor-floor-lamp-e10.webp",
        title: "eufy Indoor Floor Lamp E10",
        price: "299 ₾",
        description: [
            {
                key: "RGBWW:",
                value: "16 მილიონი ფერი",
            },
            {
                key: "ფერების ტემპერატურა:",
                value: "1500K - 9000K",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "",
            },
            {
                key: "ხმით მართვა",
                value: "",
            },
        ],
        shortDescription: [
            {
                key: "RGBWW:",
                value: "16 მილიონიდან ნებისმიერი ფერის არჩევის შესაძლებლობა",
            },
            {
                key: "აპლიკაციით და ხმით მართვა",
                value: "",
            },
            {
                key: "ჭკვიანი სახლის ინტეგრაციები:",
                value: "Amazon Alexa, Google Assistant",
            },
            {
                key: "ფერების ტემპერატურის დიაპაზონი:",
                value: "1500K - 9000K",
            },
        ],
        video: "https://www.youtube.com/embed/PgQas8BhIl8?si=qbNkuZQ8-2tzpYwn",
    },
    {
        id: "eufy-HomeBase-S380",
        imageUrl: "/images/products/eufy-homebase-s380.webp",
        title: "eufy HomeBase S380 (HomeBase 3)",
        price: "549 ₾",
        oldPrice: "659 ₾",
        description: [
            {
                key: "კავშირი:",
                value: "Wi-Fi და LAN",
            },
            {
                key: "მეხსიერება:",
                value: "16 GB (16 TB-მდე)",
            },
            {
                key: "სახის ამოცნობა:",
                value: "BionicMind™️",
            },
            {
                key: "კვების წყარო:",
                value: "USB კაბელი",
            },
        ],
        shortDescription: [
            {
                key: "ცენტრალიზებული მეხსიერება:",
                value: "იუფის მოწყობილობების დაკავშირების შემდეგ ყველა ჩანაწერი შეინახება HomeBase 3-ზე.",
            },
            {
                key: "გაზრდადი მეხსიერება:",
                value: "შიდა 16 GB მეხსიერების გარდა, შესაძლებელია მყარი დისკის ინსტალაცია, რაც 16 TB-მდე ზრდის მეხსიერებას.",
            },
            {
                key: "BionicMind™ AI:",
                value: "კამერების HomeBase 3-თან დაკავშირების შემდეგ კამერებზე აქტიურდება სახის ამოცნობის ფუნქცია, რათა მოხდეს ოჯახის წევრებისა და უცნობების განსხვავება.",
            },
            {
                key: "დაცული და დაშიფრული:",
                value: "მოწყობილობაზე შენახული ჩანაწერები დაშიფრულია AES-128 და RSA-1024 ალგორითმით.",
            },
        ],
        video: "https://www.youtube.com/embed/MrdabEBWgkg?si=10Ir_4WR0UHuXObd&amp;start=11",
        specs: [
            {
                key: "დაკავშირების რაოდენობა",
                value: (
                    <>
                        <li>16-მდე მოწყობილობა</li>
                        <li>34-მდე სენსორი</li>
                    </>
                ),
            },
            {
                key: "AI ამოცნობა",
                value: (
                    <>
                        <li>თვით-სწავლის ალგორითმი</li>
                        <li>სახის ამოცნობა</li>
                        <li>ადამიანის დაფიქსირება</li>
                        <li>ავტომობილის დაფიქსირება</li>
                        <li>შინაური ცხოველის დაფიქსირება</li>
                    </>
                ),
            },
            {
                key: "სიგნალიზაცია",
                value: "კი (100db)",
            },
            {
                key: "აპლიკაციით მართვა",
                value: "კი",
            },
            {
                key: "მეხსიერება",
                value: (
                    <>
                        <li>შიდა მეხსიერება: 16 GB EMMC</li>
                        <li>მყარი დისკი: 16 TB-მდე (არ მოყვება)</li>
                    </>
                ),
            },
            {
                key: "პროცესორი",
                value: "Quad-core ARM Cortex-A55",
            },
            {
                key: "კავშირი",
                value: (
                    <>
                        <p>Wi-Fi (2.4GHz)</p>
                        <p>LAN (10/100/1000 Mbps)</p>
                    </>
                ),
            },
            {
                key: "ჩანაწერების დაშიფვრის ალგორითმები",
                value: "AES-128 + RSA-1024",
            },
            {
                key: "კვების წყარო",
                value: "USB კაბელი და ადაპტერი",
            },
            {
                key: "რა არის ყუთში",
                value: (
                    <>
                        <li>HomeBase 3</li>
                        <li>USB-C კაბელი</li>
                        <li>ადაპტერი</li>
                        <li>ინსტრუქცია</li>
                    </>
                ),
            },
            {
                key: "გარანტია",
                value: "1 წელი",
            },
        ],
    },
];
